import * as Sentry from "@sentry/browser";
import * as SentryIntegrations from "@sentry/integrations";

const SentryEnvHostnameMap: { [key: string]: string } = {
  "excalidraw.com": "production",
  "vercel.app": "staging",
};

const REACT_APP_DISABLE_SENTRY =
  process.env.REACT_APP_DISABLE_SENTRY === "true";

// Disable Sentry locally or inside the Docker to avoid noise/respect privacy
const onlineEnv =
  !REACT_APP_DISABLE_SENTRY &&
  Object.keys(SentryEnvHostnameMap).find(
    (item) => window.location.hostname.indexOf(item) >= 0,
  );

// Sentry.init({
//   dsn: onlineEnv
//     ? "https://7bfc596a5bf945eda6b660d3015a5460@sentry.io/5179260"
//     : undefined,
//   environment: onlineEnv ? SentryEnvHostnameMap[onlineEnv] : undefined,
//   release: process.env.REACT_APP_GIT_SHA,
//   ignoreErrors: [
//     "undefined is not an object (evaluating 'window.__pad.performLoop')", // Only happens on Safari, but spams our servers. Doesn't break anything
//   ],
//   integrations: [
//     new SentryIntegrations.CaptureConsole({
//       levels: ["error"],
//     }),
//   ],
//   beforeSend(event) {
//     if (event.request?.url) {
//       event.request.url = event.request.url.replace(/#.*$/, "");
//     }
//     return event;
//   },
// });
